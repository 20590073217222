<template>
  <div>
    <router-view v-show="this.$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="this.$route.matched.length==2">
      <div class='main-page-content' v-if="userPermissions.indexOf('mcn_promote_list') !== -1">
        <!-- 搜索-->
        <el-form :inline='true' label-position='right' class='table-search'>
          <el-form-item>
            <el-date-picker
                @change="handleQuery"
                v-model="selectInfo.month"
                type="month"
                align="right"
                unlink-panels
                placeholder="选择月份"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select @change="handleQuery" filterable clearable placeholder="选择红人" v-model="selectInfo.artist_id">
              <el-option :key="index" :label="item.nickname" :value="item.artist_id" v-for="(item,index) in kolList">
                <span style="float: left"> {{ item.nickname }} </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click='handleQuery' type='primary' icon='el-icon-search'>查询</el-button>
            <div class="update download" v-if="userPermissions.indexOf('mcn_promote_import') !== -1">
              <el-upload
                  :before-upload="beforeUpload"
                  :disabled="loadingUpload"
                  :file-list="file"
                  ref="upload"
                  :auto-upload="false"
                  :data="{http_id:httpId}"
                  :headers="uploadHeaders"
                  :on-change="handleChange"
                  :on-success="importSuccess"
                  :show-file-list="false"
                  action="/admin_api/kol_analy_mcn/import"
                  class="upload-demo"
                  multiple
              >
                <el-button :icon="`${loadingUpload?'el-icon-loading':'el-icon-upload2'}`"
                           :loading="loadingUpload"
                           size="mini" type="primary">批量MCN推广费
                </el-button>
              </el-upload>

            </div>
            <export-btn ref="refExportLabor"
                        :can-export="userPermissions.indexOf('mcn_promote_import') !== -1"
                        :lintTitle="'MCN推广费模板'"
                        class="download"
                        @export="downLoadLabor"/>

            <el-button @click="uploadFile" type="primary"
                       style="margin-left: 10px" v-if="userPermissions.indexOf('mcn_promote_import') !== -1">导入记录
            </el-button>
          </el-form-item>
        </el-form>
        <div class="default-table">
          <el-table :data="dataList" ref="multipleTable" row-key="id"
                    @selection-change="handleSelectRow"
                    v-loading="isLoading" border>
            <el-table-column
                type="selection"
                width="40">
            </el-table-column>
            <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
            <el-table-column align="center" label="月份"
                             show-overflow-tooltip prop="month"></el-table-column>
            <el-table-column align="center" label="红人昵称" min-width="150" prop="nickname"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="平台" min-width="150" prop="platform_name"
                             show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="right" label="金额" prop="mcn_promote" show-overflow-tooltip
                             :formatter="formatNumber"></el-table-column>
            <el-table-column align='center' width="130" label='操作'>
              <template slot-scope="scope">
                <el-popover
                    v-if="userPermissions.indexOf('mcn_promote_save') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.visible">
                  <div style="margin: 10px 0">
                <span style="display: inline-block">
                  输入修改金额:
                </span>
                    <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='amount' placeholder='输入修改金额'/>
                  </div>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="danger" size="mini" @click="edit(scope.row)">确定</el-button>
                  </div>
                  <el-link @click="clickEdit(scope.row)" size="mini" slot="reference" type="info">修改</el-link>
                </el-popover>
                <el-popover
                    style="margin-left: 10px;display: inline-block"
                    v-if="userPermissions.indexOf('mcn_promote_delete') != -1"
                    placement="top"
                    width="150"
                    v-model="scope.row.delVisible">
                  <p>确定要删除记录吗？</p>
                  <div style="text-align: right; margin: 0;">
                    <el-button type="text" size="mini" @click="scope.row.delVisible=false">取消</el-button>
                    <el-button type="danger" size="mini" @click="deleteData(scope.row.id)">确定</el-button>
                  </div>
                  <el-link slot="reference" type="danger" size="mini">删除</el-link>
                </el-popover>

              </template>
            </el-table-column>
          </el-table>
          <br>
          <el-button :disabled="delIds.length===0" icon='el-icon-delete' type='primary' @click='selectDel'
                     v-if="userPermissions.indexOf('mcn_promote_delete') !== -1">批量删除
          </el-button>
          <el-row>
            <el-col :span='24' style='text-align: right'>
              <Pagination :limit.sync='pagingData.page_size'
                          :page.sync='pagingData.current_page'
                          :total='pagingData.total'
                          @pagination='getList'/>
            </el-col>
          </el-row>

        </div>
        <!--    右键菜单-修改红人考核月份-->
        <el-dialog :before-close="handleClose" title="导入记录" v-if="uploadFileShow" :visible.sync="uploadFileShow"
                   width="90%">
          <upload-file-list :progressShow="progressShow" :percentage="percentage" :progressTextShow="progressTextShow"
                            @closeDialog="closeDialog" :fileName="fileName" :progressText="progressText"
                            :uploadType="'mcn_promotion'" :reloadData="reloadData"
                            :uploadFileShow="uploadFileShow"></upload-file-list>
        </el-dialog>

      </div>
    </PageHeaderLayout>
  </div>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ArtistSearch from '@/components/artist/ArtistSearch'
import GroupSelect from '@/pages/team/components/GroupSelect'
import Contextmenu from '@/pages/kolModel/components/Contextmenu.vue'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import { getToken } from '@/utils/auth'
import uploadFileList from '@/components/uploadIndex'

export default {
  name: 'cost_index',
  components: {
    PageHeaderLayout, ArtistSearch, GroupSelect, Contextmenu, ExportBtn, uploadFileList
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      dataList: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      selectInfo: {},
      kolList: [],
      isLoading: false,
      delIds: [],
      visible: false,
      file: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      loadingUpload: false,
      WebSocket: '',
      lockReconnect: false, // 连接状态标识
      reconnectTimeout: 3 * 1000, // websocket重连时间间隔 ms
      httpId: '',
      percentage: 0,
      progressText: '',
      progressShow: false,
      uploadFileShow: false,
      progressTextShow: false,
      wsStop: false,
      fileName: '',
      reloadData: false,
      amount: ''
    }
  },
  methods: {
    clickEdit(row) {
      this.amount = row.amount
    },
    closeDialog(val) {
      val ? this.uploadFileShow = false : ''
      this.getList()
    },
    uploadFile() {
      this.uploadFileShow = true
      this.progressShow = false
      // this.reloadData = true
    },
    async edit(row) {
      // laborEdit
      let id = await this.$api.mcnPromotionEdit({ id: row.id, mcn_promote: this.amount })
      if (id) {
        this.$message.success('修改成功!')
        row.amount = this.amount
        await this.getList()

      }
    },
    //导入
    //上传模块
    //上传模块
    handleClose() {
      this.wsStop = false
      this.uploadFileShow = false
      // this.uploadFileShow = true
      this.progressShow = false
      this.reloadData = false

    },
    ws() {
      if (!this.wsStop) {
        let url = ''
        if (process.env.NODE_ENV === 'development')
          url = `${process.env.VUE_APP_WS_URL}/mcn_promotion/import?http_id=${this.httpId}`
        else {
          const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
          const hostname = window.location.hostname
          url = `${wsProtocol}//${hostname}${process.env.VUE_APP_WS_URL}/mcn_promotion/import?http_id=${this.httpId}`
        }
        this.WebSocket = new WebSocket(url)
        if (url && url.length > 0) {

          this.WebSocket.onopen = () => {
            this.WebSocket.send('open')
            this.$refs.upload.submit()
          }
          this.WebSocket.onmessage = e => {
            this.wsOnMessage(e)
          }
          this.WebSocket.onerror = err => {
            console.log(err, 'err')
            // this.reconnect()
            // this.wsOnError(err)
          }
          this.WebSocket.onclose = res => {
            // alert(this.token)
            console.log('close', res)
          }
        } else this.$notify.warning('即时通讯地址为空')

      }
    },

    handleChange(file) {
      this.file = [file]
      this.fileName = this.file[0].name
      this.ws()
      this.wsStop = true
      this.progressShow = true
    },
    importSuccess(response) {
      this.loadingUpload = false
      this.loadingStatus = false
      if (response.code === 200) {
        this.$notify.success(response.data)
        this.submitted = []
        this.showProgress = false
        this.reloadData = true

        this.dataList = response.data.list || []
        this.getList()
      } else {
        this.$notify.warning(response.msg)
      }
    },
    importError() {
      // this.$notify.success('上传失败,请确定')
      // this.submitted = []
      this.loadingUpload = false
      this.loadingStatus = false
      this.showProgress = false
      this.uploadFileShow = false
      // this.dataList = []
      this.wsClose()
    },
    wsClose() {
      if (this.WebSocket && this.WebSocket.readyState === this.WebSocket.OPEN) {
        this.WebSocket.close()
        this.WebSocket = null
      }
    },
    beforeUpload() {
      this.httpId = Math.ceil(Math.random() * 999999999999)
      this.percentage = 0
      this.loadingUpload = true
      this.loadingStatus = true
      this.uploadFileShow = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    wsOnMessage(e) {
      // this.debugLogger('wsOnMessage:', e)
      const str = JSON.parse(e.data)
      if (!str) return
      if (str.type === 'upload' || str.type === 'parse') {
        this.progressTextShow = true
        this.progressText = str.msg
      }

      if (str.type === 'process') {
        this.percentage = str.process
        if (str.process == 100) {
          setTimeout(() => {
            this.progressTextShow = false
          }, 500)
          // this.progressShow = false
          this.wsClose()
        }
      }
    },
    reconnect() {
      if (this.lockReconnect) {
        return
      }
      this.lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      // tt && clearTimeout(tt);
      setTimeout(() => {
        this.initWebSocket()
        this.lockReconnect = false
      }, this.reconnectTimeout)
    },
    // downLoadLabor
    async downLoadLabor() {
      try {
        let isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let response = await this.$api.exportMcnPromotionTemplate()
          let name = `MCN推广导入模板`
          this.$refs.refExportLabor.exportSaveXlsx(response, name)
        }
      } catch (e) {
        this.$message.warning('浏览器不支持导出文件')
      }
    },

    handleSelectRow(val) {
      this.delIds = val.map(i => {
        return i.id
      })

    },
    selectDel() {
      if (this.delIds?.length != 0) {
        this.$confirm(
            `确认批量删除${this.delIds.length}条数据吗？`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
          this.deleteData(this.delIds)
        })
      } else {
        this.$message({
          message: '请选择数据', type: 'info'
        })
      }
      // this.deleteData()
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addData()
        } else {
          return false
        }
      })
    },

    async deleteData(ids) {
      let data = Array.isArray(ids) ? ids : [ids]
      let id = await this.$api.mcnPromotionDel({ ids: data })
      if (id) {
        this.$message({
          message: '删除成功', type: 'success'
        })
        await this.getList()
      }
    },
    async getKol() {
      this.kolList = await this.$api.modelGetKolList()
    },
    async getList() {
      Object.assign(this.selectInfo, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      })
      const { list, page_info } = await this.$api.mcnPromotionGetKolList(this.selectInfo)

      this.dataList = list
      this.pagingData = page_info
      this.isLoading = false
    },
    detailPage(row) {
      const { href } = this.$router.resolve({
        name: 'modelDetail',
        params: { id: row.artist_id },
        query: {
          year: row.month.slice(0, 4),
          month: row.month.slice(5, 7)
        }
      })
      window.open(href, '_blank')
    },
    formatNumber(row, col) {
      return this.$utils.numberFormat(row[col.property], 2, '.', ',', 'round')
    },

    handleQuery() {
      if (this.selectInfo.artist_id === '') {
        delete this.selectInfo.artist_id
      }
      if (this.selectInfo.month === null) {
        delete this.selectInfo.month
      }
      this.pagingData.current_page = 1
      this.getList()
    }
  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        this.handleQuery()
        this.getKol()
      }
    }
  },

  mounted() {
    this.httpId = Math.ceil(Math.random() * 999999999999)
    if (this.$route.matched.length === 2) {
      this.handleQuery()
      this.getKol()
    }
    // console.log(this.$route.matched)
  }
}
</script>

<style lang="scss" scoped>
.addNewBox {
  width: 90%;
  margin: 0 auto;

  > * {
    padding: 10px;
  }
}


.download {
  font-size: 18px;
  margin-left: 12px;
  color: #ff3176;
  cursor: pointer;
  display: inline-grid !important;
}
</style>

